<template>
    <v-row>
        <purposesCloudResume />

        <exportReportFull />

        <purposesCloudHistory />
    </v-row>
</template>

<script>
import purposesCloudResume from '@/components/admin/dashboard/purposesCloudResume'
import purposesCloudHistory from '@/components/admin/dashboard/purposesCloudHistory'
import exportReportFull from '@/components/admin/dashboard/exportReportFull'

export default {
    name: "AdminDashBoard",
    components: { purposesCloudResume, exportReportFull, purposesCloudHistory },
    data: () => ({

    })
};
</script>
