<template>
    <v-col
        cols="12"
        md="8"
        style="display: flex; justify-content: flex-end"
    >
        <v-btn
            class="ma-2"
            dark
            color="primary"
            @click="exportReport"
        >
            <v-icon>mdi-file-chart</v-icon>
            {{ $t('admin.dashboard.exportReportGeneral') }}
        </v-btn>
    </v-col>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    name: 'ExportReportFull',
    data: function () {
        return {

        }
    },
    created() {

    },
    methods: {
        async exportReport() {
            this.$store.dispatch('loading/start');

            try {
                const res = await axios
                    .get('dashboard/exportFullReportToExel', { responseType: 'arraybuffer' });

                const blob = new Blob([res.data], { type: 'application/*' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'General Report ' + this.dateHelper.currentDateTimeToFileName() + '.xlsx';
                link._target = 'blank';
                link.click();
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }

            this.$store.dispatch('loading/end');
        }
    }
}
</script>

<style scoped>

</style>
