<template>
    <v-col
        cols="12"
        sm="6"
        md="4"
    >
        <base-card>
            <v-list-item three-line>
                <v-list-item-content>
                    <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                    <div class="text-body-2 mb-0 text--disabled">
                        Total de propósitos en la Nube
                    </div>
                    <v-list-item-title
                        class="text-h5 mb-1 font-weight-bold"
                    >
                        <span> {{ `${purposesCloud.count} de ${purposesCloud.usersCount} usuarios` }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <div class="d-flex flex-wrap">
                            <v-sheet
                                height="20"
                                width="20"
                                class="info lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                            >
                                <v-icon
                                    class="info--text text-caption"
                                >
                                    mdi-arrow-top-right
                                </v-icon>
                            </v-sheet>
                            <span
                                class="font-weight-bold text-primary"
                            >{{ `Publicados hoy: ${purposesCloud.countToday} (${numberHelper.percent(purposesCloud.countToday, purposesCloud.count)} %)` }}</span>
                        </div>
                    </v-list-item-subtitle>
                </v-list-item-content>

                <apexchart
                    width="100"
                    height="150"
                    type="radialBar"
                    :options="jmPieChartOne.chartOptions"
                    :series="jmPieChartOne.series"
                />
            </v-list-item>
        </base-card>
    </v-col>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    name: 'PurposesCloudResume',
    data: function () {
        return {
            purposesCloud: { count: 0, countToday: 0, usersCount: 0 },
            jmPieChartOne: {
                series: [0],
                chartOptions: {
                    chart: {
                        height: 150,
                        type: "radialBar"
                    },

                    colors: ["#006ff5"],
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 0,
                                size: "60%"
                            },

                            dataLabels: {
                                show: true,
                                name: {
                                    offsetY: 0,
                                    show: false,
                                    color: "#888",
                                    fontSize: "13px"
                                },
                                value: {
                                    offsetY: 10,
                                    color: "#828d99",
                                    fontSize: "20px",
                                    show: true,
                                    fontWeight: 700
                                }
                            }
                        }
                    },

                    stroke: {
                        lineCap: "round"
                    },
                    labels: ["Progress"]
                }
            }
        }
    },
    created() {
        this.fetchPurposesCloudPublished();
    },
    methods: {
        async fetchPurposesCloudPublished() {
            try {
                const purposesCloudPublished = await axios
                    .get('dashboard/purposesCloudPublished');

                this.purposesCloud = purposesCloudPublished.data;
                this.jmPieChartOne.series = [this.numberHelper.percent(purposesCloudPublished.data.count, purposesCloudPublished.data.usersCount, 0)];
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
